body {
  margin: 0;
  width: 100%;
  min-width: 280px;
  font-family: "Noto Sans KR", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: black;
  text-decoration: none;
}
.inner_content > p > a {
  color: blue;
  text-decoration: !important;
}

button,
input,
optgroup,
select,
textarea {
  line-height: 1.15;
  background-color: transparent;
}

button,
input {
  overflow: visible;
}
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  font-family: inherit;
  background-repeat: no-repeat;
  background-position: center center;
  box-sizing: border-box;
}

.ql-html-buttonGroup > button {
  background-color: #518fcf;
  color: white;
  padding: 3px 5px;
  border-radius: 5px;
}
