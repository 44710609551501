.board {
  width: 100%;
  /* margin: 0 auto; */
}
.borad_list {
  width: 850px;
  margin: 0 auto;
}
@media (max-width: 850px) {
  .borad_list {
    width: 100%;
  }
}
.borad_list > div:first-child {
  border-top: 2px solid #5289ee;
}
.list_item {
  display: table;
  table-layout: fixed;
  width: 100%;
  min-width: 280px;
  min-height: 78px;
  box-sizing: border-box;
  background-color: white;
  padding: 13px 0px 0px 13px;
  margin: 0 auto;
  border-bottom: 1px solid #dadada;
}
.thumnail {
  display: table-cell;
  width: 70px;
  height: 55px;
  vertical-align: middle;
}
.thumnail > img {
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
.thumnail_noimage {
  opacity: 0.2;
}
.list {
  display: table-cell;
  vertical-align: middle;
}
.board_title {
  font-size: 13px;
  margin-left: 20px;
  color: black;
  display: block;
  max-width: 80%;
}
.board_title > span {
  color: #ed4b5f;
  font-size: 13px;
}
.content_wrap {
  margin-top: 5px;
  margin-left: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content_wrap .category {
  padding-left: 2px;
}
.content_wrap > span {
  line-height: 18px;
  font-size: 13px;
  padding-left: 8px;
}
.readlike {
  font-size: 14px;
  display: table-cell;
  width: 70px;
  vertical-align: middle;
  text-align: left;
}
.fa-heart:before {
  content: "\f004";
}
.like_eye {
  margin-top: 5px;
}

.list_headerwrap {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 5;
}
.search_result {
  margin-left: 20px;
}
.list_search_wrap {
  display: flex;
  padding-right: 20px;
}

.list_search_wrap .searchbox {
  vertical-align: middle;
  border: 1px solid #dadada;
  border-radius: 6px;
  overflow: hidden;
  height: 40px;
  margin-right: 5px;
}

.header_input {
  padding: 0 10px;
  width: 160px;
  color: #000;
}
.category-search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 850px;
  margin: 0 auto;
  min-height: 40px;
  line-height: 40px;
  min-width: 250px;
  padding: 10px 0;
}
.search_result {
  width: 850px;
  margin: 0 auto;
  font-size: 13px;
}
@media (max-width: 850px) {
  .category-search {
    width: 100%;
  }
}
/* 페이지네이션 */
.commu_paging {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.paging_btn {
  display: inline-block;
  width: 20px;
  height: 28px;
  line-height: 26px;
  color: #555;
  text-align: center;
  margin: 3px;
  overflow: hidden;
  font-size: 0.9em;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
/* 페이징끝 */
.list_header_btn:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f002";
  font-size: 1.2em;
  margin-right: 10px;
}
#buttonON {
  border: 0;
  line-height: 28px;
  color: #5289ee;
}
.Writebtn {
  background-color: #5289ee;
  border-color: #5289ee;
  color: #fff;
  /* width: 85px; */
  border-radius: 6px;
  height: 36px;
  line-height: 32px;
  padding: 0 15px;
  /* font-size: 1.2em; */
}
.categoryWrap {
  margin: 0 !important;
}
.wrap_sort {
  display: inline-block;
  margin-left: 20px;
  height: 25px;
  line-height: 30px;
  font-size: 15px;
  color: #555;
}

@media (max-width: 550px) {
  .write_text {
    display: none;
  }
  .wrap_sort {
    font-size: 12px;
    margin-left: 20px;
    margin-right: 5px;
  }
  .header_input {
    width: 120px;
  }
}

@media (max-width: 450px) {
  .wrap_sort {
    font-size: 12px;
    margin-left: 10px;
    margin-right: 0px;
  }
  .list_search_wrap {
    padding-left: 0px;
    padding-right: 10px;
  }
  .header_input {
    width: 80px;
  }
}

@media (max-width: 350px) {
  .wrap_sort {
    font-size: 11.5px;
    margin-left: 5px;
    margin-right: 0px;
  }
  .list_search_wrap {
    padding-left: 0px;
    padding-right: 5px;
  }
  .header_input {
    width: 60px;
  }
}

.wrap_sort.red {
  color: #5289ee;
}

/* 게시판 디테일 시작 */
.board-detail {
  margin: 0 auto;
  width: 850px;
}

@media (max-width: 850px) {
  .board-detail {
    width: 100%;
  }
}
.board-view {
  margin-top: 40px;
  border: 1px solid #dadada;
}

.view-header {
  padding: 20px 30px;
  border-bottom: 1px solid #dadada;
  background: #fff;
}
.board-view .view-header .title {
  font-size: 1.3em;
  word-break: keep-all;
}
.board-view .view-info {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 770px) {
  .view-info {
    flex-direction: column;
  }
}

.board-view .view-info .info .in {
  padding-right: 10px;
  color: #737373;
}
.info .in:after {
  position: relative;
  top: 0px;
  left: 5px;
  height: 10px;
  vertical-align: middle;
  content: "";
  border-left: 1px solid #dddfe4;
}
.info .in:last-child::after {
  content: none;
}
.inner_content {
  min-width: 250px;
  width: 100%;
  box-sizing: border-box;
  padding: 24px 30px;
  line-height: 24px;
  font-size: 16px;
  color: #000;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
}

.board-view .view-footer {
  position: relative;
  padding: 20px 30px;
  border-top: 1px solid #dadada;
  text-align: center;
}
.board-view .view-vote .btn {
  display: inline-block;
  padding: 10px 25px;
  margin: 0 10px;
  font-size: 1.1em;
  border: 1px solid #dadada;
  border-radius: 6px;
  vertical-align: middle;
  transition: 0.5s;
}
.board-view .view-vote .btn.good {
  border-color: #518fcf;
  color: #518fcf;
}

.board-view .view-vote .btn.good > i {
  float: left;
  margin-right: 10px;
}
.board-view .view-vote .btn.bad > i {
  float: right;
  margin-left: 10px;
  transform: scaleX(-1);
}
.board-view .view-vote .btn.bad {
  border-color: #e86352;
  color: #e86352;
}
.fa-thumbs-down:before {
  content: "\f165";
}
/* 글쓰기페이지 */

.write_page {
  margin: 0 auto;
  width: 850px;
  margin-top: 20px;
}
@media (max-width: 850px) {
  .write_page {
    width: 100%;
  }
}
.board_write {
  border-top: 2px solid #518fcf;
  padding-top: 10px;
}
.Write_input_wrap {
  margin-bottom: 10px;
}
.Write_input_wrap > input {
  width: 100%;
  background-color: #fff;
  border: 1px solid #dadada;
  padding: 10px 16px 9px;
  line-height: 19px;
  font-size: 16px;
  color: #000;
}

.ql-container.ql-snow {
  height: 500px;
  color: #000;
  border: 1px solid #dadada !important;
  border-top: 0 !important;
}
.Write_button_wrap {
  text-align: center;
  margin-top: 10px;
}
.Write_button_wrap > button {
  font-size: 1.1em;
  width: 120px;
  height: 40px;
  color: #000;
  line-height: 38px;
  border: 1px solid #dadada;
  border-radius: 4px;
  margin-left: 10px;
}
.Write_button_wrap .write_submit {
  background-color: #518fcf;
  border-color: #518fcf;
  color: #fff;
}

.inner_content > p > img {
  margin: 4px 0 10px;
  box-sizing: border-box;
  vertical-align: top;
  max-width: 100%;
  /* width: auto; */
  height: auto;
}
.board-reply {
  border: 1px solid #dadada;
  margin-top: 10px;
}
.board-reply .reply-header {
  position: relative;
  padding: 20px 30px;
  border-bottom: 1px solid #dadada;
  font-size: 1.1em;
  color: #737373;
}
.board-reply .reply-header .title {
  font-size: 1.1em;
  margin-right: 10px;
  color: #333;
}
.board-reply .reply-write {
  padding: 20px 30px;
  background: #fff;
}
.board-reply .reply-write .rep {
  border: 1px solid #dadada;
  background: #fff;
  padding: 20px;
  width: 100%;
  height: 80px;
  display: block;
  color: black;
  appearance: none;
  resize: none;
}
.board-reply .reply-write .write-foot {
  height: 50px;
  border: 1px solid #dadada;
  border-top: 0;
  background: #fff;
  padding-left: 20px;
}
.write-file {
  height: 50px;
  display: flex;
  justify-content: space-between;
}
.comment_image_upload_btn > i {
  font-size: 35px;
  color: black;
  opacity: 0.5;
}
.fa-image:before {
  content: "\f03e";
}
.comment_submit_btn {
  background-color: #518fcf;
  border-color: #518fcf;
  color: #fff;
  border-radius: 6px;
  line-height: 32px;
  width: 60px;
  margin-top: 5px;
  margin-right: 10px;
  height: 40px;
  font-size: 13px;
}
.commnet_sort {
  border: 1px solid #dadada;
  border-top: 0;
  color: black;
}
.sort_btn {
  padding: 12px 30px;
  color: black;
}
.sorton {
  color: #518fcf;
  border-bottom: 3px solid #518fcf;
}
.comment_box {
  padding: 15px 30px 5px 30px;
  border: 1px solid #dadada;
  border-top: 0;
}
.comment_topWrap {
  margin-bottom: 10px;
  justify-content: space-between;
  display: flex;
}
.comment_wrap {
  font-size: 14px;
}
.diver {
  display: inline-block;
  width: 1px;
  height: 10px;
  background: #dadada;
  margin: 0 10px;
  content: "";
}
.comment_bottomWrap {
  margin-top: 10px;
  margin-bottom: 10px;
}
.comment_bottomWrap > span {
  color: #777;
}
.recomment {
  font-size: 13px;
  cursor: pointer;
}
.recomment_box {
  width: 100%;
  padding-left: 60px;
  /* position: relative; */
  border-top: 1px solid #dadada;
  right: 30px;
}
.recomment_topWrap {
  margin-top: 15px;
  margin-bottom: 10px;
  justify-content: space-between;
  display: flex;
}
.recomment_topWrap .time {
  color: #777;
  font-size: 13px;
}
.re {
  border: 0;
  margin-top: 0;
  margin-bottom: 15px;
}
.board-reply .reply-write {
  padding: 20px 30px;
  background: #fff;
}
.write-file {
  height: 50px;
  display: flex;
  justify-content: space-between;
}
.board-reply .reply-write .write-foot {
  height: 50px;
  border: 1px solid #dadada;
  border-top: 0;
  background: #fff;
  padding-left: 20px;
}
.reply-write .preview {
  margin-top: 5px;
  width: 55px;
  height: 40px;
}
.reply-write .comment_image_name {
  margin-left: 10px;
}
.delete_image {
  font-size: 15px;
  cursor: pointer;
  line-height: 3.3;
  margin-left: 5px;
}
.image_file_name {
  display: flex;
}
.comment_image_name {
  line-height: 3;
}
.recommnet_id {
  background-color: #fff;
  padding: 0 5px;
  background-color: #518fcf;
}
.delete_n_edit {
  margin: 0 auto;
  width: 100%;
  background-color: #fff;
  margin-bottom: 10px;
}
.de {
  margin-top: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: flex-end;
}
.de > button {
  min-width: 46px;
  height: 36px;
  margin-left: 10px;
  padding: 0 12px;
  font-size: 13px;
  line-height: 36px;
  background-color: #518fcf;
  color: #fff;
  border-radius: 6px;
  text-align: center;
  margin-left: 5px;
}
.notice_span {
  color: red;
}
